<template>
	<div id="fitness-wrap">
		<Header2022 :navbg='true'></Header2022>
		<div class="banner">
			<div class="">
				<div class="title">创新型共享健身平台</div>
			</div>
			<div class="centent">
				“小院健身”是基于小院生活平台内的共享健身版块而衍生出的创新型健身会员平台，平台通过链接物业资源（酒店配套健身房，连锁品牌健身房、私教工作室等）、教练资源、消费者资源三方，针对会员提供合作健身平台内各种特色健身房相应服务，让大家享受“多层次、多品牌、多区域、多体验"健身服务。
			</div>
			<el-popover placement="top" width="300" trigger="hover">
				<img src="../../assets/images/xiaochengxu-lifehall.png">
				<el-button slot="reference">了解更多</el-button>
			</el-popover>
		</div>
		<div class="card mobile">
			<img src="../../assets/images/life/fitness1.png" >
			<div class="">
				<div class="onecard">健身一卡通</div>
				<div class="onecard-content">
					“健身一卡通”是基于”小院生活”平台内的共享健身版块而衍生出的创新型健身会员平台,平台通过链接物业资源（酒店配套健身房，连锁品牌健身房、私教工作室等）、教练资源、消费者资源三方，针对平台会员实行一卡通用制,用户可享受健身平台合作内各种特色健身房相应服务，享受“多层次、多品牌、多区域、多体验”服务;
				</div>
			</div>
		</div>
		<div class="stores">
			<div class="title">经开未来城门店简介</div>
			<div class="intr">
				小院健身（经开未来城店）位于渭南市临渭区经开未来城小院生活图书馆二层，总占地面积500平米，区域包含有器械区、有氧区、动感单车房、瑜伽室、体测室、私教室、男女淋浴间等，设施设备齐全，能够为周边热爱健身的消费群体提供优质的健身场所体验。
			</div>
			<div class="function">
				<div class="title">经开未来城店各功能区展示</div>
				<div class="area-list">
					<div class="area-item">
						<img src="../../assets/images/life/fitness2.png" >
						<div class="name">私教室</div>
					</div>
					<div class="area-item">
						<img src="../../assets/images/life/fitness3.png" >
						<div class="name">单车房</div>
					</div>
					<div class="area-item">
						<img src="../../assets/images/life/fitness4.png" >
						<div class="name">瑜伽室</div>
					</div>
					<div class="area-item">
						<img src="../../assets/images/life/fitness5.png" >
						<div class="name">器械区</div>
					</div>
				</div>
			</div>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	export default {
		components: {
			Header2022,
			Footer2022,	
		},
		data() {
			return {

				
			}
		},

		methods: {
			cutTap(e){
				
			}
		}
	}
</script>

<style lang="scss">
	#fitness-wrap{
		.title{
			font-size: 42px;
			font-weight: bold;
			line-height: 42px;
			padding-top: 20px;
			text-align: center;
		}
		.subtitle{
			font-size: 30px;
			line-height: 30px;
			margin-top: 20px;
		}
		.centent {
			width: 1102px;
			font-size: 18px;
			line-height: 30px;
			text-align: center;
		}
		@media only screen and (max-width:1440px){
			.centent{
				width: 840px;
				font-size: 16px;
			}
		}
		.el-button{
			font-size: 12px;
			text-align: center;
			cursor: pointer;
			width: 258px;
			color:#FFFFFF;
			background-color: rgba(0, 0, 0, 0);
			height: 42px;
			border: 2px solid #FFFFFF;
			border-radius: 20px;
		}
		.btn {
			font-size: 12px;
			line-height: 42px;
			text-align: center;
			cursor: pointer;
			width: 258px;
			height: 42px;
			border: 2px solid #FFFFFF;
			border-radius: 20px;
		}
		.banner {
			width: 100%;
			height: 100vh;
			color: #FFFFFF;
			background: url('../../assets/images/life/fitnessbg1.png') no-repeat;
			background-size: cover;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
		}
		.mobile{
			background: url('../../assets/images/life/fitnessbg2.png') no-repeat;
			background-size: 100% 100%;
			color: #FFFFFF;
			height: 100vh;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.onecard{
				font-size: 40px;
				font-weight: bold;
				padding-left: 360px;
				margin-bottom: 48px;
			}
			.onecard-content{
				width: 917px;
				font-size: 16px;
				line-height: 32px;
			}
			img{
				width: 367px;
				margin-right: 69px;
			}
			@media only screen and (max-width:1366px){
				img{
					width: 294px;
					margin-right: 55px;
				}
				.onecard-content{
					width: 733px;
				}
			} 
		}
		.stores{
			color: #000000;
			padding: 88px 0 118px;
			.title{
				font-size: 40px;
				font-weight: bold;
				line-height: 40px;
				text-align: center;
			}
			.intr{
				width: 1139px;
				font-size: 16px;
				line-height: 32px;
				margin: 34px auto 120px;
				text-align: center;
			}
			.area-list{
				display: flex;
				padding-top: 77px;
				justify-content: space-around;
				overflow: hidden;
				.area-item{
					margin: 0 10px;
					width: 19.9%;
				}
				img{
					width:100%;
				}
				.name{
					font-size: 24px;
					font-weight: bold;
					line-height: 24px;
					text-align: center;
					margin-top: 28px;
				}
			}
		}
	}
</style>